<template>
  <div class="home header-color">
    <my-header :title="`הארנק של משפחת ${user.lastName}`" myClass />
    <v-container
      class="form-container d-flex justify-center flex-column body-color"
    >
      <div class="d-flex justify-center">
        <div class="avatar-img d-flex justify-center mt-n16">
          <v-avatar size="126" class="avatar">
            <img src="/person.png" lazy-src="/person.png" />
          </v-avatar>
        </div>
      </div>
      <div class="hllow-user d-flex justify-center" v-if="user">
        שלום,
        <br />
        {{ user.firstName }}
        {{ user.lastName }}
      </div>
      <v-container style="height: 40vh" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" align="center" justify="center" v-if="password">
            <span>הסיסמה החדשה:</span>
          </v-col>
          <v-col cols="12" align="center" justify="center" v-if="password">
            <v-btn @click="copyPassword">
              {{ password }}
            </v-btn>
          </v-col>
          <v-col cols="12" align="center" justify="center" v-if="password">
            <span>(להעתקת הסיסמה לחץ על הכפתור)</span>
          </v-col>
          <v-col cols="12" align="center" justify="center">
            <v-text-field
              :rules="[rules.min]"
              v-model="newPassword"
              counter="25"
              label="לשינוי סיסמה"
            />
            <v-btn
              @click="changePassword"
              :disabled="!valid"
              v-text="'שמירת הסיסמה'"
              color="success"
            />
          </v-col>
          <v-col align="center" justify="center">
            <v-btn to="/" color="primary">לדף הבית</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";

export default {
  components: {
    MyHeader,
  },
  name: "ResetPassword",
  data() {
    return {
      password: "",
      newPassword: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "מינימום 6 תווים",
      },
    };
  },
  methods: {
    async changePassword() {
      try {
        await this.$store.dispatch("auth/change-password", {
          password: this.newPassword,
        });
        await this.$router.push("/");
      } catch (e) {
        console.log(e);
      }
    },
    copyPassword() {
      navigator.clipboard.writeText(this.password);
      this.$store.commit("invokeSnack", {
        msg: "הועתק בהצלחה!",
        type: "success",
      });
    },
  },
  computed: {
    valid() {
      return this.newPassword.length > 5;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.password = this.$route?.params?.password;
    this.$route.params.password = "";
    this.$router.replace("/reset-password");
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 9px !important;
}

.home > .header {
  height: 160px;
}
</style>
